.custom_select {
    width: 20px;
    height: 30px;
    position: relative;
    margin-top: 5px;
      }
      .error_input {
        mix-blend-mode: normal;
        border: 1px solid #DB504A !important;
    border-radius: 100px;
    color: #0D0F11;
    padding: 5px 8px;
    opacity: 0.8;
    width: 290px;
    height: 30px;
     appearance: none;
     -webkit-appearance: none;
     -moz-appearance: none;
     background-image: url('../../../Assets/select_arrow.svg');
     background-repeat: no-repeat;
     background-position: right center;
     padding-right: 20px;
     background-repeat: no-repeat;
     background-position: right 10px center;
     outline: none;
     display: flex;
     align-items: center;
     font-size: 14px;
      }
      
      .selected_option {
        mix-blend-mode: normal;

    border-radius: 100px;
    color: #0D0F11;
    padding: 0px 8px;
    opacity: 0.8;
    border: none;
    width: 40px;
    height: 30px;
    border-right: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 0;
    padding: 0 8px;
     appearance: none;
     -webkit-appearance: none;
     -moz-appearance: none;
     background-image: url('../../../Assets/select_arrow.svg');
     background-repeat: no-repeat;
     background-position: right center;
     padding-right: 20px;
     background-repeat: no-repeat;
     background-position: right 10px center;
     outline: none;
     display: flex;
     align-items: center;
     font-size: 14px;
      }
      .disabled_option {
    background-color: rgba(239, 239, 239, 0.3);
    mix-blend-mode: normal;

    border-radius: 100px;
    color: #0D0F11;
    padding: 0px 8px;
    opacity: 0.8;
    border: none;
    width: 40px;
    height: 30px;
    border-right: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 0;
    padding: 0 8px;
     appearance: none;
     -webkit-appearance: none;
     -moz-appearance: none;
     background-image: url('../../../Assets/select_arrow.svg');
     background-repeat: no-repeat;
     background-position: right center;
     padding-right: 20px;
     background-repeat: no-repeat;
     background-position: right 10px center;
     outline: none;
     display: flex;
     align-items: center;
     font-size: 14px;
      }
      
      .options {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 10;
        background: #FFFFFF;
        
        border: 1px solid rgba(4, 4, 21, 0.08);
        border-radius: 10px;
    
    
        /* margin-top: 20px; */
        padding: 0;
        list-style-type: none;
        width: 60px;
        max-height: 200px;
        overflow-y: auto;
    
      }
      
      .options li {
        padding: 10px 8px;
        
        cursor: pointer;
        font-size: 14px;
    
    
      }
      
      .options li:hover {
        background-color: #85CE52;
      }
      
      @media screen and (min-width: 1800px) {
        .custom_select,      .selected_option {
          width: 50px;
          height: 40px;
          position: relative;
          margin-top: 0px;
          font-size: 18px;
            }
      }