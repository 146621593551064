.subContainer{
    width: 60%;
    background-size: 100% 100%;
    background-repeat: no-repeat;


}

.subContainer h1{
    text-align: center;
    margin-top: 40px;
    font-size: 30px;
    color: #0B0D0F;
    font-family: 'SF Pro Display', sans-serif;
                                                

}
.authImage{
    position: relative;
}
.authImage img{
    position: absolute;
    width: 80%;
    margin-top: 20px;
    left: -80px;
}
.subContainerMobile{
    display: none;
}

@media screen and (min-width: 2300px) {
    .subContainer h1{
        font-size: 40px;
    }
    .subContainer{
        width: 60%;

        background-repeat: no-repeat;
    
    
    }
}
@media screen and (max-width: 1200px){
    .subContainer{
        display: none;
    }
    .subContainerMobile{
        display: grid;
        width: 100%;
        height: 50%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

@media screen and (max-width:500px) {
    .subContainerMobile{
        display: grid;
        width: 100%;
        height: 30%;
        background-size: cover;
        background-repeat: no-repeat;
    }    
}

@media  screen and (max-width: 1550px) {
    .authImage img{
        width: 95%;
    }
}

@media (min-width: 1200px) and (max-width:1700px) {
    .subContainer{
        width: 70%;
   
    
    
    }

}