.card{
    display: flex;
    align-items: center;
    grid-column-gap: 50px;
    width: 100%;
    /* flex-wrap: wrap; */
    grid-row-gap: 25px;
}
.info{
    background: #FFFFFF;
border-radius: 14px;
display: flex;
align-items: center;
padding: 15px 20px;
width: 69%;
justify-content: space-between;
flex-wrap: wrap;
height: 210px;
}
.live button{
    background: #B5FFE9;
}
.block button{
    background: var(--error, #DB504A);
    color: #fff;
}


.info_details span{
 display: flex;
 align-items: center;
 grid-column-gap: 10px;
 margin: 20px 0;
}
.info_details  span h1{
    font-weight: 600;
font-size: 24px;
margin: 0;

color: #333333;

}
.info_details  span h3{
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    color: #333333;
    

}
.info_details  span img{
    width: 20px;
    

}

.info_status div{
   display: grid;
}
.info_status label{
    font-weight: 500;
font-size: 14px;


color: #000000;
margin: 10px 0;

}
.info_status button{
border-radius: 43px;
border: none;
padding: 10px;
width: 150px;
font-weight: 600;


color: #0D0F11;

}

.card_container{
display: grid;
grid-template-columns: 1fr;
grid-row-gap: 20px;

}
.hide{
    display: none !important;
}


@media screen and  (max-width:890px) {
    .info{

    width: 100%;
  
    }
    .card_container{

    display: grid;
    justify-content: space-between;
    
    width: 100%;
    }        
}


@media screen and  (max-width:660px) {
    .info{

    width: 100%;
  
    }
.card{
    display: grid;
    width: 85%;
}
    .card_container{

        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
     
    }      
    .info_details  span h1{
        font-weight: 600;
    font-size: 18px;
    margin: 0;
    
    color: #333333;
    
    }
    .info_details  span h3{
        font-weight: 500;
        font-size: 16px;
        margin: 0;
        color: #333333;
        
    
    } 
}
.button_container{
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.tooltip_text {
    position: absolute;
    transform: translateX(-50%);
    top: 100%;
    left: 50%;
    color: var(--color-text-main, #333);
    font-size: 15px;
    border-radius: 10px;
    background: var(--color-white, #FFF);
    /* Shadow03 */
    box-shadow: 0px 1px 28px 0px rgba(0, 0, 0, 0.12);
    font-weight: 600;
  background-color: #fff;
    padding: 5px;
    margin-top: 8px;
    z-index: 3;

display: flex;
justify-content: center;
align-items: center;
text-align: center;
 width: 150px;
 height: 30px;


  }
  
  .tooltip_text::before {
    content: '';
    position: absolute;

    bottom: 100%;
    left: 80%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
  
@media screen and  (max-width:480px) {
    .info{
   
    height: 300px;
    }
    
}
