  
.modalContent {

    border-radius: 14px;
    background: var(--color-white, #FFF);
    margin: 10% auto;
  
  
    max-width: 320px;
    padding: 20px 30px;
  
  
  
  }
  
  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
  
  
  }
  
  .modalContent h1 {
    color: #000;
    font-size: 22px;
    font-family: Poppins;
    font-weight: 500;
    margin: 30px 0 35px 0;
  }
  
  .hideModal {
    display: none;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 30px;
    margin-top: 20px;
  }
  
  .clear {
    border-radius: 45px;
    border: 1px solid var(--primary-color, #85CE54);
    background: var(--white, #FFF);
    border-radius: 100px;
    padding: 8px 20px;
    height: 38px;
    padding: 12px 24px;
    font-size: 12px;
    color: #85CE54;
  
    font-weight: 600;
  }
  .modalContent .form_input input {
    mix-blend-mode: normal;
    border: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 100px;
    color: #0d0f11;
    padding: 5px 8px;
    opacity: 0.8;
    width: 300px;
    height: 30px;
    outline: none;
  }
  .modalContent .form_input label, .modalContent .select_input label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
  
    letter-spacing: 0.4px;
  
    color: #0d0f11;
  }
  .modalContent .form_input{
    display: grid;
    margin: 20px 0;
  }
  .modalContent .select_input {
    display: grid;
    margin:10px 0 30px 0;
  }  
  .solid,
.solid_add {
  background: #85CE54;
  /* Primary Color */

  border: 1px solid #85CE54;
  border-radius: 100px;
  color: #FFFFFF;
  border-radius: 150px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
}
.solid:hover,
.solid_add:hover {
  background: #FFFFFF;
  color: #85CE54;
  border: 1px solid #85CE54;
}
.flex_input{
  display: flex;
column-gap: 10px;
}
.form_input ::placeholder {
  color: #0d0f11;
}
.flex_input .form_input input{
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 140px;
  height: 30px;
  outline: none;
}