.no_content{
   
    width: 100%;
   margin-top: 15%;
   text-align: center;
}

.no_content h3{
    font-weight: 600;
font-size: 18px;

color: #000000;
margin: 0;
}

.no_content p{

font-weight: 400;
font-size: 12px;
line-height: 18px;
margin-top: 5px;
color: #9D9D9D;

}

.no_content button{
    font-weight: 600;

color: #FFFFFF;
background: #85CE54;
border-radius: 50px;
border: 1px solid #85CE54;
height: 40px;
font-size: 16px;
margin-top: 10px;
padding: 10px 30px;

text-align: center;



}
.no_content button:hover{
    font-weight: 600;
font-size: 18px;
color: #85CE54;
border: 2px solid #85CE54 !important;
background: #FFFFFF;
border-radius: 50px;
border: none;
height: 40px;
font-size: 16px !important;
margin-top: 10px;
padding: 10px 20px;

text-align: center;



}

@media screen and (min-width: 1800px){
    .no_content img{
        width: 200px;
    }
    .no_content{
   

       margin-top: 10%;
 
    }
    .no_content h3{
   
        font-size: 25px;
        margin: 10px 0;
      
        }
        
        .no_content p{
        

        font-size: 18px;
    
        
        }
        
        .no_content button{
            font-weight: 600;
        font-size: 20px;
height: 60px;
   

        margin-top: 10px;
        padding: 15px 30px;
        

        
        
        
        }
        .no_content button:hover{
            font-weight: 600;
        font-size: 18px;
        color: #85CE54;
        border: 2px solid #85CE54 !important;
        background: #FFFFFF;
        border-radius: 50px;
        border: none;
        height: 40px;
        font-size: 16px !important;
        margin-top: 10px;
        padding: 10px 20px;
        
        text-align: center;
        
        
        
        }
}


@media  screen and (max-width: 500px) {
    .no_content{
   
        width: 100%;
       margin: 20%  0;
       text-align: center;
       height: 100vh;
    }
   
}


@media screen and (max-width:890px){
    .no_content{
   
        width: 100%;
       margin: 20%  0;
       text-align: center;
       height: 100vh;
    }
}