.authForm{
    padding: 30px 80px ;
    width: 40%;
 
   
}
.hide{
    display: none;
}
.form{
    display: flex;
  
    width: 100%;
}
.logo img{
    width: 200px;
}
.header{
    margin-top: 30px;
}
.header h1{
    margin: 0;
}
.header p{
    font-size: 13px;
    color: #A3A3A3;
   font-weight: 500;
   margin:  0;
}
.authForm form{
    margin: 30px 0;
    display: grid;
}
.formControl{
  margin: 20px 0;
  width: 85%;
}
.formInput input, .errorInput input{
    border: none;
    outline: none;
 
    margin-left: 10px;
    width: 95%;
}
.formInput{
    border: 1px solid #A3A3A3;

    border-radius: 53px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
}
.errorInput{
    border: 1px solid #DB504A;

    border-radius: 53px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
}
.formInput img,.errorInput img{
    width: 20px;
}
.formControl label{
    font-weight: 500;
}
.authForm button{
    width: 85%;
    color: #FFFFFF;
    background: #85CE54;
border-radius: 44px;
padding: 15px 10px;
border: none;
font-size: 18px;
font-weight: 700;
margin: 30px 0;
height: 50px;
}

.authForm button:hover{

    background: #FFFFFF;
    color: #85CE54;
    border: 2px solid #85CE54;

}
.authForm button:active{
    color: #FFFFFF;
    background: #0C462D;
    border: none;
    
}
.otp_text{
    display:flex;
    justify-content:center;
    width:85%;
    margin-top:-35px
}
@media  screen and (min-height: 1000px) {
    .form{
        display: flex;
        margin-top: 100px;
        width: 100%;
    }

}
@media screen and (min-width: 1800px) {
    .logo img{
        width: 300px;
    }
    .header h1{
        margin: 0;
        font-size: 40px;
    }
    .header p{
        font-size: 18px;
        color: #A3A3A3;
       font-weight: 500;
    }
    .formInput img,.errorInput img{
        width: 35px;
    }
    .formInput, .errorInput{
        height: 40px;
        padding: 10px 15px;
        align-items: center;
    }
    .formInput input, .errorInput input{
        border: none;
        outline: none;
        font-size: 18px;
        margin-left: 10px;
        width: 95%;
        margin-left: 0;
    }
    .formControl label{
        font-size: 20px;
    }
    .header h1{
        font-size: 40px;
    }
    .header p{
        font-size: 18px;
  
    }

}
@media screen and (max-width: 1200px){
    .authForm{
        width: 100%;
        padding: 40px 40px;
    }
    .authForm form{
        margin: 10px 0;
        display: grid;
    }
    .formControl{
        margin: 20px 0;
        width: 100%;
      }
      .logo{
        display: flex;
        justify-content: center;
      }
      .logo img{
        width: 300px;
    }
    .header{
        text-align: center;
        margin-top: 20px;
    }
    .header h1{
        margin: 0;
        font-size: 40px;
    }
    .header p{
        font-size: 18px;
        color: #A3A3A3;
       font-weight: 500;
    }
    .authForm button{
        width: 100%;
    }
    .otp_text{
      
        width:100%;
      
    }
}

@media screen and (max-width: 600px){
    .authForm{
        padding: 40px 0px;
      
       
    }
    .authForm form{
        padding: 0 30px;
    }
    .logo img{
        width:150px;
    }
    .header h1{
        font-size: 25px;
    }
    .header p{
       margin: 5px;
       font-size: 15px;
    }
    .otp_text{
      
        width:100%;
      
    }
}