.filter{
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap:10px;
}

@media screen and (min-width:1800px) {
    .filter{
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 30px;
        grid-row-gap:30px;
    }
    
}
.filter .select_input {
    width: 320px;
    height: 60px;
    margin: 8px 0;
  }
  .filter  .select_input label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
  
    letter-spacing: 0.4px;
  
    color: #0d0f11;
  }

.buttons{
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 30px;
    margin-top: 20px;
}
.clear{
    background: #FFFFFF;

border: 1px solid #85CE54;
border-radius: 100px;
color: #85CE54;
border-radius: 100px;
padding: 8px 20px;
}
.solid{
    background: #85CE54;
/* Primary Color */

border: 1px solid #85CE54;
border-radius: 100px;
color:  #FFFFFF;
border-radius: 150px;
padding: 8px 20px;
display: flex;
align-items: center;
grid-column-gap: 10px;
}
.solid:hover{
    background: #FFFFFF;
    color: #85CE54;
    border: 1px solid #85CE54;
}

.solid:active{
    color: #FFFFFF;
    background: #0C462D;
    border: none;
    
}

.action_icons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.action_icons div{
    display: flex;
    grid-column-gap: 10px;
    align-items: center;
    grid-row-gap: 20px;
    flex-wrap: wrap
}
.action_icons img{
    width: 20px;
}
.filter .form_input label{
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
  
    letter-spacing: 0.4px;
  
    color: #0d0f11;
  }
  .filter .input_container{
    margin-top: 10px;
  }
  
  .filter .form_input input {
    mix-blend-mode: normal;
    border: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 100px;
    color: #0d0f11;
    padding: 5px 8px;
    opacity: 0.8;
    width: 300px;
    height: 30px;
    outline: none;
  }
  