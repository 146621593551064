.container{
    display: flex;
    color: #121212;
height: 100vh;
                                                
    font-family: 'Cairo', sans-serif;

}
.formControl{
    margin: 20px 0;
    width: 85%;
  }
  .formInput input, .errorInput input{
    border: none;
    outline: none;
  
    margin-left: 10px;
    width: 100%;
}
.formInput{
    border: 1px solid #A3A3A3;

    border-radius: 53px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
}
.errorInput{
    border: 1px solid #DB504A;

    border-radius: 53px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
}
.formInput img,.errorInput img{
    width: 20px;
}
  .formControl label{
      font-weight: 500;
  }
  .formInputTwo{
    display: flex;
    justify-content: flex-start;
     align-items:center ;
      grid-column-gap:10px;
       width:100%;
}

  @media screen and (max-width: 1200px){
 
    .formControl{
        margin: 20px 0;
        width: 100%;
      }
      .container{
        display: grid;
        color: #121212;
    height: 100vh;
                                                    
        font-family: 'Cairo', sans-serif;
    
    }
  
}
@media screen and (min-width: 1800px) {
    .logo img{
        width: 300px;
    }
    .header h1{
        margin: 0;
        font-size: 40px;
    }
    .header p{
        font-size: 18px;
        color: #A3A3A3;
       font-weight: 500;
    }
    .formInput img,.errorInput img{
        width: 35px;
    }
    .formInput, .errorInput{
        height: 40px;
        padding: 10px 15px;
        align-items: center;
    }
    .formInput input, .errorInput input{
        border: none;
        outline: none;
        font-size: 18px;
        margin-left: 10px;
        width: 95%;
    }
    .formControl label{
        font-size: 20px;
    }
    .header h1{
        font-size: 40px;
    }
    .header p{
        font-size: 18px;
  
    }

}