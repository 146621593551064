.tooltip_container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip_trigger {
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip_text {
    position: absolute;
    transform: translateX(-50%);
    top: 150%;
    left: 50%;
    color: var(--color-text-main, #333);
    border-radius: 10px;
    background: var(--color-white, #FFF);
    /* Shadow03 */
    box-shadow: 0px 1px 28px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding: 10px 5px;
    width: max-content;
    height: 15px;
    font-weight: 600;
    z-index: 3;
    font-size: 12px;
    text-align: center !important;
    opacity: 1; /* Add this line to make the tooltip solid */
}

  
  .tooltip_text::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    opacity: 1;
    z-index: 3;
    border-color: transparent transparent #fff transparent;
  }
  