.header{
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #ACACAC;
    padding: 0 8px;
    flex-wrap: wrap;
}
.header button{
    background: none;
    border: none;
    padding: 5px 0;
    font-weight: 400;
font-size: 15px;
text-align: center;
}
.toggle{
    background: #FFFFFF;
border-radius: 14px;
padding:30px  20px;
margin-top: 30px;

overflow-y: scroll;
 
}
.show{
    display: block;
}
.hide{
    display: none;
}

/* 
@media screen and (max-width: 650px) {
    .header{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ACACAC;
        padding: 0 8px;
        fl
    }
} */