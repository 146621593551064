.content table {

    
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;

    
  }
  .content{
    width: 80%;
    margin: auto;
  }
  .content h1{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
  }
  .content h3{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
  }
  .flex_end{
    font-weight: 800;
    display: flex;
    justify-content: flex-end;
    font-family: Poppins;

    align-items: center;
    column-gap: 10px;
  }
  .flex_end span{
    cursor: pointer;
  }
   .bexbury_button{
    background-color: #008DA9;
    padding: 5px 20px;
    color: #fff;
    border: none;
    border-radius: 20px;
  }
  .brite_button{
    background-color: #042F53;
    padding: 5px 20px;
    color: #fff;
    border: none;
    border-radius: 20px;
  }
  .sarta_button{
    background-color: #CB411E;
    padding: 5px 20px;
    color: #fff;
    border: none;
    border-radius: 20px;
  }
  .default_button{
    background-color: #85CE54;
    padding: 5px 20px;
    color: #fff;
    border: none;
    border-radius: 20px;
  }
  .hidden{
    display: none;
  }
  
 .container{
    padding: 50px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
   
 }
 .main_table{
    margin-top: 20px;
    border-collapse: collapse;

 }

   .container tr td {
    border-bottom: 1px dashed rgba(13, 15, 17, 0.1);
    font-weight: 400;
    font-size: 14px;
    padding: 10px 15px;
    color: #040415;
  
   
  
  }
  
  .default th {
    padding: 10px 15px;
    border: none;
    font-weight: 600;
    font-size: 14px;
   
    text-align: left;
  
  
    color: #fff;
  
  }
  .defaultItem{
    padding: 10px 15px;
    border: none;
    font-weight: 600;
    font-size: 14px;

    text-align: left;
  
  
    color: #fff;
  }
  .defaultItem td{
    color: #fff !important;
  }
  .table_div{
    width: 100% !important;
  }
  .table_div table{
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
  }
  .defaultBox{
  
    border-width: 1px;
    border-style: solid;
   text-align: right;
   margin: 20px 0;

   padding: 10px;
   align-items: center;

  }
  .defaultBox h2{
  
    font-weight: 500;
  }
  .defaultBox h3{
  
    font-weight: 600;
    font-size: 25px;
  }
  .bexbury  th{
    padding: 10px 15px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    color: #0d0f11;
    text-align: left;
    background-color: #008DA9;
  
    
    color: #fff;
  
  }
  .sarta th{
    padding: 10px 15px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    color: #0d0f11;
    text-align: left;
    background-color: #CB411E;
    
    color: #fff;
  
  }
  .brite th{
    padding: 10px 15px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    color: #0d0f11;
    text-align: left;
    background-color: #042F53;
    
    color: #fff;
  
  }
  .flex{
    display: flex;
    justify-content: space-between ;
    align-items: flex-start;
    column-gap: 30px;

  }
 