.content_header h1 {
    font-weight: 600;
    font-size: 18px;
    color: #313131;
    margin: 0;
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;
  }
  
  .content_header p {
    font-weight: 600;
    font-size: 12px;
    color: #9D9D9D;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }
.content{
  padding: 0 30px;
}


.edit{
    display: flex;
    justify-content: flex-end;
}
.add{
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
.hidden button{ 
  visibility: hidden;
}
.edit button, .add button {
  background: #85CE54;
  /* Primary Color */

  border: 1px solid #85CE54;
  border-radius: 100px;
  color: #FFFFFF;
  border-radius: 150px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
}


.edit .icon, .edit_icon {
  background: url("../../../Assets/edit_white.svg");

  height: 60%;
  background-size: contain;
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}
.add .add_icon {
  background: url("../../../Assets/add-icon.png");

  height: 60%;
  background-size: contain;
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}
.add button:hover>.add_icon{
  background: url("../../../Assets/add-square.svg");

  height: 20px;
  background-size: contain;
  width: 20px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

.edit button:hover>.icon {
  background: url("../../../Assets/edit.svg");

  height: 15px;
  background-size: contain;
  width: 15px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

.edit button:hover, .add button:hover{
  background: #FFFFFF;
  color: #85CE54;
  border: 1px solid #85CE54;
}
.table_input{
  border-radius: 15px;
  border: 1px solid var(--text-main-color, #0D0F11);
  padding: 5px;
}
.table_input::placeholder{
  color: black !important;
}
.form_container{
  display: flex;
  justify-content: flex-start;
  column-gap: 40px;
  row-gap: 10px;
  flex-wrap: wrap;
}
.form_input input::placeholder{
  color: black !important;
}
.form_input input {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 200px;
  height: 30px;
  outline: none;
}
.form_input {
  display: grid;
  margin: 8px 0;
}
.form_input label {
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0 5px 0;

  letter-spacing: 0.4px;

  color: #0d0f11;
}

.icon_image{
  width: 30px;
}
.flex{
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.flex img {
  width: 35px;
  height: 35px;
}
.table table {

  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;

  
}
.table tr td {
  border-bottom: 1px dashed rgba(13, 15, 17, 0.1);
  font-weight: 400;
  font-size: 14px;
  padding: 10px 15px;
  color: #040415;
  text-align: left;
 

}
.table tr th {
  padding: 10px 15px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #0d0f11;
  text-align: left;



  background: #F8F9FA;

}
.table_head_input input, .table_body_input input{
  border-radius: 20px;
  border: 1px solid var(--Text-Main-Color, #0D0F11);
  height: 22px;
  max-width: 60px;
  padding: 5px;
}
.table_head_input input::placeholder,  .table_body_input input::placeholder{
  color: #040415;
}
.table_head_input img , .delete_icon img{
  width: 35px;
  height: 35px;
}
.table_head_input{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}
