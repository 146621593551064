.hide {
  display: none !important;
}
.show {
  display: block;
}
 
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.bulk {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-column-gap: 5px;
  height: 40px;
}

.bulk img {
  width: 18px;
}
.header h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;

  color: #0d0f11;
}
.header button {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: #ffffff;
  border: none;
  background: #85ce54;
  border-radius: 100px;
  padding: 8px 20px;
}

.bulk_icon{
background: url("../../../Assets/upload.svg");

height: 20px;
background-size: contain;
width: 20px;
background-repeat: no-repeat;
padding: 0;
margin: 0;
}
.header button span{
  margin-right: 5px;
}
.header button:hover > .bulk_icon{
background: url("../../../Assets/next_green.svg");

height: 20px;
background-size: contain;
width: 20px;
background-repeat: no-repeat;
padding: 0;
margin: 0;
}
.header button:hover{
  background: #FFFFFF;
  color: #85CE54;
  border: 1px solid #85CE54;
}


.add_new{
  margin-top: 25px;
  width: 35px;
}
.form_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-column-gap: 30px;
    max-height: 120px;
}
.form_input {
  display: grid;
  margin: 8px 0;
}
.select_input {
  width: 320px;
  /* max-height: 80px; */
  margin: 8px 0;
}
.select_input .label {
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0 5px 0;

  letter-spacing: 0.4px;

  color: #0d0f11;
}
.form_input label {
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0 5px 0;

  letter-spacing: 0.4px;

  color: #0d0f11;
}
.clearBtn{
  background: #FFFFFF;

  border: 1px solid #85CE54;
  border-radius: 100px;
  color: #85CE54;
  border-radius: 100px;
  padding: 8px 20px;
  margin-top: 20px;
}
.saveBtn{
  background: #85CE54;
  /* Primary Color */

  border: 1px solid #85CE54;
  border-radius: 100px;
  color: #FFFFFF;
  border-radius: 150px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  margin-top: 20px;
}
.form_input input {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 300px;
  height: 30px;
  outline: none;
}
.form_input select {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 320px;
  height: 40px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../../Assets/select_arrow.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 20px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  outline: none;
}
.error_input{
  border: 1px solid #DB504A !important;

  border-radius: 53px;
  padding: 10px;
  margin-top: 5px;
  display: flex;
}
.custom_select_container {
  position: relative;
}

.tel {
  display: flex;

  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  width: 320px;
  height: 40px;
  grid-column-gap:50px;

}
.error_input_tel{

    display: flex;
  
    border: 1px solid #DB504A ;
    border-radius: 100px;
    width: 320px;
    height: 40px;
    grid-column-gap:50px;
  

}
.tel select {
  border: none;
  width: 70px;
  border-right: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 0;
  padding: 0 8px;
}
.tel input, .error_input_tel input {
  border: none;

    mix-blend-mode: normal;
 
    border-radius: 100px;
    color: #0d0f11;
    padding: 5px 8px;
    opacity: 0.8;
    width: 220px;
    height: 30px;
    outline: none;

}

.form_add {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 50px;
}
.form_add img {
  width: 30px;
  margin-top: 15px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom_switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_switch:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .custom_switch {
  background-color: #85ce54;
}

input:focus + .custom_switch {
  box-shadow: 0 0 1px #85ce54;
}

input:checked + .custom_switch:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded custom_switch s */
.custom_switch {
  border-radius: 34px;
}

.custom_switch:before {
  border-radius: 50%;
}

.toggle_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 100px;
  flex-wrap: wrap;
}
.toggle_container div {
  display: flex;
  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 10px;
}
.toggle_container p {
  font-weight: 400;
  font-size: 16px;

  letter-spacing: 0.2px;

  color: #0d0f11;
}



@media screen and (min-width: 1800px) {
    .form_input input, .tel {
      width: 500px;
      height: 50px;
      font-size: 18px;
    }
    .tel input {
      border: none;
    
        mix-blend-mode: normal;
     
        border-radius: 100px;
        color: #0d0f11;
        padding: 5px 8px;
        opacity: 0.8;
        width: 420px;
        height: 30px;
        outline: none;
    
    }
    .select_input {
      width: 520px;
      height: 60px;
      margin: 8px 0;
    }
    .select_input label, .form_input label{
      font-size: 18px;
    }
    .header h3{
      font-size: 20px;
    }
    .bulk {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      grid-column-gap: 10px;
      height: 50px;
    }
    
    .bulk img {
      width: 20px;
    }
}