.card{
    background: #FFFFFF;
    box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
    border-radius: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 220px;
    padding: 15px;
    grid-column-gap: 15px;
    height: 80px;
}
.icon{
    width: 55px;
}

.cardContent h1{
    margin: 0;
    color: #333333;
    font-size: 25px;
    font-weight: 700;
} 
.cardContent p{
    margin: 0;
    color: #333333;
    font-weight: 400;
    font-size: 16px;

}

@media screen and (min-width: 1700px) {
    .card{
        width: 250px;
    }
}

@media screen and (min-width: 2000px) {
    .card{
        width: 300px;
      
    }
}
@media screen and (min-width: 2300px) {
    .card{
        width: 400px;
        height: 100px;
      
    }
}
@media screen and (min-width: 2980px) {
    .card{
        width: 600px;
        height: 150px;
        padding: 18px;
    }
    .cardContent h1{
        font-size: 30px;
    } 
    .cardContent p{
        font-size: 23px;
    }
    .icon{
        width: 80px;
    }
}
/* 
@media screen and (max-width:1300px){
    .card{

        width: 240px;

    }

} */

@media  (max-width: 890px) and (min-width:811px){
    .card{
        width: 320px;
        padding: 18px;
    }
    .cardContent h1{
        font-size: 30px;
    } 
    .cardContent p{
        font-size: 23px;
    }
    .icon{
        width: 80px;
    }

}

@media screen and (max-width: 790px) {
    .card{
        width: 100%;
        padding: 18px;
    }
    .cardContent h1{
        font-size: 30px;
    } 
    .cardContent p{
        font-size: 23px;
    }
    .icon{
        width: 80px;
    }

}
