.table h1 {
  font-weight: 600;
  font-size: 24px;
  color: #313131;
  margin: 0;

}
.showText {
  font-weight: 600;
  font-size: 12px;

  margin:5px 0;

  color: #9d9d9d;
}
.content {
  background: #ffffff;

  border-radius: 10px;
  padding: 15px 20px;
  min-height: 470px;
  margin-top: 20px;
}
.contentHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  grid-row-gap:20px;
}
.searchBar input {
  border: none;
  width: 90%;
}
.searchBar img {
  width: 15px;
}
.searchBar {
  padding: 5px 10px;

  width: 250px;
  height: 30px;
  border-radius: 100px;
  border: 1px solid rgba(4, 4, 21, 0.08);
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
}
.filter {
  background: #ffffff;
  border: 1px solid #85ce54;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  color: #85ce54;
  grid-column-gap: 10px;
  width: 100px;
}
.add {
  background: #85ce54;
  border: 1px solid #85ce54;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  color: #ffffff;
  grid-column-gap: 10px;
  width: 180px;
  font-weight: 600;
  height: 40px !important;
}
.add img {
  width: 18px;
}
.table table {

  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;

  
}

/* .table thead {
  background: #f4f5f6;
  width: 100%;
}



 */
 .table tr td {
  border-bottom: 1px dashed rgba(13, 15, 17, 0.1);
  font-weight: 400;
  font-size: 14px;
  padding: 10px 15px;
  color: #040415;

 

}

.table thead th {
  padding: 10px 15px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: #0d0f11;
  text-align: left;

  background: #F8F9FA;

}

/* .table td div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 15px;
} */

.footer {

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  padding-bottom: 10px;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 10px;

}
.row h3 {
  font-weight: 500;
  font-size: 14px;

  letter-spacing: 0.4px;

  color: #0d0f11;
}
.row button {
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  padding: 8px 10px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 5px;
  
}

.hide {
  display: none;
}
 
/* .table table {
    display: flex;
    flex-direction: column;
    width: 100%;
} */

.table_container {
  overflow-x: auto;
  min-height: 550px;
  padding:15px 10px 20px 15px;

}

.add_image{
  background: url("../../../Assets/add-icon.png");
  background: contain;
  height: 100%;
  background-size: contain;
  width: 20px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

.add:hover > .add_image{
  background: url("../../../Assets/green-square.svg");
  background: contain;
  height: 100%;
  background-size: contain;
  width: 20px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;

}
.add:hover{
  border: 1.5px solid #85ce54;
  background: #ffffff;
  color: #85ce54;
}
.head_icon{
  width: 35px;
  height: 35px;
}

.paginate {
  display: flex;

 list-style: none;
 align-items: center;
 column-gap: 10px;


}
.paginate a {
  text-decoration: none;
  color: inherit;
 
  cursor: pointer;
  padding: 3px 8px;
  margin: 0 5px;



}
.options {
  position: absolute;
  bottom: 25px;
  left: 0;
  z-index: 10;
  background: #FFFFFF;
  
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 5px;


  /* margin-top: 20px; */
  padding: 0;
  list-style-type: none;
  width: 80px;
  max-height: 100px;
  overflow-y: auto;

}
.options li {
  padding:  8px;
  
  cursor: pointer;
  font-size: 14px;


}
.options li:hover {
  background-color: #85CE52;
}



.paginate_next{
  background: none !important;
border: 1px solid black;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
padding: 4px  !important;
}
/* .next{
  background: none !important;
  border: 1px solid black;
  border-radius: 50%;



}

.ppagvious{

}

.prev{
  background: none !important;
  border: 1px solid black;
  border-radius: 50%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

} */
.activeLink{
  background:#85CE54 !important;
  border-radius: 50%;
  color: white !important; 
  padding: 5px 12px !important;
}
.activeLink a {
  color: white ; 
}
 .activePaginate{
  background:#85CE54;
 
  color: white; 
}

@media screen and (max-width:890px) {
  .searchBar {

  
    width: 200px;
  
  }
  
}

@media screen and (min-width: 1800px) {
  .table tr td {
    border-bottom: 1px dashed rgba(13, 15, 17, 0.1);
    font-weight: 400;
    font-size: 18px;
    padding: 10px 15px;
    color: #040415;
  
   
  
  }
  .table thead th {
    padding: 10px 15px;
    border: none;
    font-weight: 600;
    font-size: 18px;
    color: #0d0f11;
    text-align: left;
  
  
    background: #F8F9FA;
  
  }
  
  
}