.modalContent{
    border-radius: 8px;
    border: 1px solid var(--yellow-y-400, #FFB020);
    background: var(--yellow-y-50, #FFFAF1);
    margin: 20% auto;
    
    width: 400px;
    min-width: max-content;
    



   padding: 15px;
}
.modal{
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4);
    display: block;
    
        
    }
    .hideModal{
        display: none;
    }
    .buttons{
        display: flex;
        justify-content: flex-end;
        grid-column-gap: 30px;
        margin-top: 20px;
    }
    .clear{
        border-radius: 100px;
        border: 1px solid var(--text-main-color, #0D0F11);
        background: var(--white, #FFF);
    border-radius: 100px;
    padding: 8px 20px;
    height: 38px;
padding: 12px 24px;
font-size: 12px;

font-weight: 600;
    }
    .solid{
 border: none;
        
    border-radius: 100px;
    padding: 8px 20px;
    height: 38px;
padding: 12px 24px;
background: #FFB020;
color: var(--color-white-100, #FFF);
text-align: center;
font-size: 12px;

font-weight: 600;
    }

    .modal_header{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        grid-column-gap:5px;
    }
    .modal_header h3{
        color: var(--yellow-y-500, #996A13);

font-size: 14px;

font-weight: 500;
margin: 0;
    }
    .modalContent p{
        color: var(--text-main-color, #0D0F11);
/* Paragraph / P-200 */
font-size: 14px;
margin-left:5px 20px ;
    }


    @media screen and (max-width: 450px) {
        .modalContent{
          
            
            width: 300px;
           
        }
    }