.no_content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
   height: 75vh;
   background: #ffffff;
   margin-top: 20px;
   border-radius: 14px;

}
.content{
    font-family: 'Poppins', sans-serif;
}

.content h1{
    font-weight: 600;
    font-size: 22px;
    margin: 0;
    color: #313131;
   
}
.content p{
    font-weight: 600;
    font-size: 12px;
  margin: 5px 0;
    color: #9D9D9D;

}

.no_content h3{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;


text-align: center;



color: #ACACAC;
}

.filter {
    background: #ffffff;
    border: 1px solid #85ce54;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: center;
    color: #85ce54;
    grid-column-gap: 10px;
    width: 100px;
    margin-bottom: 20px;
  }
.report_container{
    background: #ffff;
    padding: 20px;
    border-radius: 20px;
}
.conversation{
    margin: 20px 0;
}
.conversation_left{
    text-align: right;
    margin: 20px 10px;
}
.header{
    display: flex;
 column-gap: 10px;
 align-items: flex-start;
}
.conversation .header, .conversation_left .header{
    display: grid;
} 
.conversation p, .conversation_left p{
    margin: 0;
    color: #0D0F11;
}
.conversation_container{
    min-height: max-content;
    max-height: 500px;
    overflow-y: scroll;
    
}
.sender{
    display: flex;
    justify-content: flex-end;
}
.grid h4{
    margin: 0;
    font-size: 15px;
    
}
.grid p{
    margin: 0;
    font-size: 12px;
}
.send_report{
    width: 100%;
    display: flex;
    column-gap: 10px;
    margin: 20px 0;
}
.send_report button{
    background: #85CE54;
    /* Primary Color */
    
    border: 1px solid #85CE54;
    border-radius: 100px;
    color:  #FFFFFF;
    border-radius: 150px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
}
.send_report button:hover{
    background: #FFFFFF;
    color: #85CE54;
    border: 1px solid #85CE54;
}
.send_report input{
    mix-blend-mode: normal;
    border: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 100px;
    color: black;
    padding: 5px 8px;
    opacity: 0.8;
    width: 90%;
    height: 30px;
    outline: none;
}
.header img{
    width: 40px;
    height: 40px;
}
.header h3{
    margin: 5px 0;
    font-size: 16px;

font-weight: 600;
}
.header p{
    color: #0D0F11;
    margin: 0;
    font-size: 12px;
}
.view{
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    align-items: center;
    height: 25px;
    cursor: pointer;
}
.view p{
    color: #85CE54;
    font-weight: 600;

}
.view img{
    width: 15px;
    height: 15px;
}
.report_content h4 {
    font-size: 16px;
    margin: 10px 0 5px 0;

font-weight: 600;
}
.report_content p{
    font-size: 15px;
font-style: normal;
font-weight: 400;
color: #0D0F11;
}

.close{
    display: flex;
    justify-content: center;
}

.close button{
    background: #DB504A;
    border-radius: 100px;
    color:  #FFFFFF;
    border-radius: 150px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    border: none;
}



.paginate {
    display: flex;
  
   list-style: none;
   align-items: center;
   column-gap: 10px;
  
  
  }
  .paginate_next{
    background: none !important;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px  !important;
  }
  .paginate a {
    text-decoration: none;
    color: inherit;
   
    cursor: pointer;
    padding: 3px 8px;
    margin: 0 10px;
  
  
  
  }

  .activeLink{
    background:#85CE54 !important;
    border-radius: 50%;
    color: white !important; 
    padding: 5px 12px !important;
  }
  .activeLink a {
    color: white ; 
  }
   .activePaginate{
    background:#85CE54;
   
    color: white; 
  }

  .filterDiv .form_input label{
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
  
    letter-spacing: 0.4px;
  
    color: #0d0f11;
  }
  .filterDiv .input_container{
    margin-top: 10px;
  }
  
  .filterDiv .form_input input {
    mix-blend-mode: normal;
    border: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 100px;
    color: #0d0f11;
    padding: 5px 8px;
    opacity: 0.8;
    width: 300px;
    height: 30px;
    outline: none;
  }
  .buttons{
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 30px;
    margin-top: 20px;
}
.clear{
    background: #FFFFFF;

border: 1px solid #85CE54;
border-radius: 100px;
color: #85CE54;
border-radius: 100px;
padding: 8px 20px;
}
.solid{
    background: #85CE54;
/* Primary Color */

border: 1px solid #85CE54;
border-radius: 100px;
color:  #FFFFFF;
border-radius: 150px;
padding: 8px 20px;
display: flex;
align-items: center;
grid-column-gap: 10px;
}
.solid:hover{
    background: #FFFFFF;
    color: #85CE54;
    border: 1px solid #85CE54;
}

.solid:active{
    color: #FFFFFF;
    background: #0C462D;
    border: none;
    
}