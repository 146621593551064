.image_card{
    position: relative;
    width: 180px;
    height: 180px;
}
.content{
    font-family: 'Poppins', sans-serif;
}
.profile_image{
  width: 100%;
  height: 100%;
}
.placeholder_image{
  
    background: #85CE54;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 1px;

    display: flex;
    justify-content: center;
    align-items: center;
 
}
.formControl label {
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0;
  
    letter-spacing: 0.4px;
  
    color: #0d0f11;
  }
  .formControl input {
    mix-blend-mode: normal;
    border: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 100px;
    color: #0d0f11;
    padding: 5px 8px;
    opacity: 0.8;
    width: 200px;
    height: 30px;
    outline: none;
  }
.liner{
  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98%;
    height: 98%;
    border-radius: 10px;
    border: 1px solid white !important;
    column-gap: 5px;
}
.placeholder_image h3{
    color: white !important;
    font-size: 40px !important; 
}
.content h1{
    font-weight: 600;
    font-size: 22px;
    margin: 0;
    color: #313131;
   
}
.content p{
    font-weight: 600;
    font-size: 12px;
  margin: 5px 0;
    color: #9D9D9D;

}
.profile_card{
    margin-top:35px;
    /* color/white */

background: #FFFFFF;
border-radius: 14px;
padding: 30px 20px;

}
.profile_content h1{
   font-weight: 600;
   font-size: 32px;
   line-height: 54px;
   margin: 0;

   
   color: #333333;
}
.content h3 , .content h4{
    margin:  0;
    font-weight: 600;
font-size: 14px;
color: #000000;
margin: 5px 0 10px 0;
}

.formControl{
    margin: 5px 0;
    width: 40%;
  }
  .formInput input, .errorInput input{
    border: none;
    outline: none;

    margin-left: 10px;
    width: 90%;
}
.formInput{
    border: 1px solid #cac8c8;

    border-radius: 53px;
    padding: 5px 10px;
    margin-top: 5px;
    display: flex;
}
.errorInput{
    border: 1px solid #DB504A;

    border-radius: 53px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
}
.formInput img,.errorInput img{
    width: 25px;
}
  .formControl label{
    color: var(--text-main-color, #0D0F11);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
 
    letter-spacing: 0.4px;
  }
  .form_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column-gap: 50px;
  margin: 25px 0;
  }
  .buttons{
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 30px;
}
.clear{
    background: #FFFFFF;

border: 1px solid #9D9D9D;
border-radius: 100px;
color: #9D9D9D;
border-radius: 100px;
padding: 8px 20px;
}
.solid{
    background: #85CE54;
/* Primary Color */

border: 1px solid #85CE54;
border-radius: 100px;
color:  #FFFFFF;
border-radius: 150px;
padding: 8px 20px;
display: flex;
align-items: center;
grid-column-gap: 10px;
}

.gray{
    background-color: #9D9D9D;
    border: 1px solid #9D9D9D;
border-radius: 100px;
color:  #FFFFFF;
border-radius: 150px;
padding: 8px 20px;
display: flex;
align-items: center;
grid-column-gap: 10px;
}
.solid:hover{
    background: #FFFFFF;
    color: #85CE54;
    border: 1px solid #85CE54;
}

.solid:active{
    color: #FFFFFF;
    background: #0C462D;
    border: none;
    
}
