.custom_select {
width: 290px;
height: 30px;
position: relative;
margin-top: 5px;
  }
  .error_input {
    mix-blend-mode: normal;
    border: 1px solid #DB504A !important;
border-radius: 100px;
color: #0D0F11;
padding: 5px 8px;
opacity: 0.8;
width: 290px;
height: 30px;
 appearance: none;
 -webkit-appearance: none;
 -moz-appearance: none;
 background-image: url('../../../Assets/select_arrow.svg');
 background-repeat: no-repeat;
 background-position: right center;
 padding-right: 20px;
 background-repeat: no-repeat;
 background-position: right 10px center;
 outline: none;
 display: flex;
 align-items: center;
 font-size: 14px;
  }
  
  .selected_option {
    mix-blend-mode: normal;
    border: 1px solid rgba(4, 4, 21, 0.08);
border-radius: 100px;
color: #0D0F11;
padding: 5px 8px;
opacity: 0.8;
width: 290px;
height: 30px;
 appearance: none;
 -webkit-appearance: none;
 -moz-appearance: none;
 background-image: url('../../../Assets/select_arrow.svg');
 background-repeat: no-repeat;
 background-position: right center;
 padding-right: 20px;
 background-repeat: no-repeat;
 background-position: right 10px center;
 outline: none;
 display: flex;
 align-items: center;
 font-size: 14px;
  }
  .disabled_option {
    mix-blend-mode: normal;
    border: 1px solid rgba(4, 4, 21, 0.08);
border-radius: 100px;
color: #0D0F11;
padding: 5px 8px;
opacity: 0.8;
width: 290px;
height: 30px;
background-color: rgba(239, 239, 239, 0.3);
 appearance: none;
 -webkit-appearance: none;
 -moz-appearance: none;
 background-image: url('../../../Assets/select_arrow.svg');
 background-repeat: no-repeat;
 background-position: right center;
 padding-right: 20px;
 background-repeat: no-repeat;
 background-position: right 10px center;
 outline: none;
 display: flex;
 align-items: center;
 font-size: 14px;
  }
  
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3;
    background: #FFFFFF;
    
    border: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 21px;


    /* margin-top: 20px; */
    padding: 0;
    list-style-type: none;
    width: 320px;
    max-height: 200px;
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;

  }
  
  .options li {
    padding: 10px 8px;
    
    cursor: pointer;
    font-size: 14px;
 


  }
  
  .options li:hover {
    background-color: #85CE52;
  }
  .searchBar input {
    border: none !important;
    width: 80%;
  }
  .searchBar img {
    width: 15px;
  }
  .searchBar {
    padding: 5px 10px;
  
    width: 90%;
    height: 30px;
    border-radius: 100px;
    border: 1px solid rgba(4, 4, 21, 0.08);
    display: flex;
    align-items: center;
    grid-column-gap: 5px;
    margin: 0 5px;
  }


@media screen and (min-width: 1800px) {
  .selected_option, .custom_select,  .error_input, .options{
    width: 490px;
    font-size: 18px;
    height: 40px;
  }
  .disabled_option{
    width: 490px;
    font-size: 18px;
    height: 40px;
  }
  .options{
    width: 500px;
    font-size: 18px;
    height: 200px;
  }
}