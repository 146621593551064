.modalContent{

    border-radius: 14px;
    background: var(--color-white, #FFF);
    margin: 10% auto;
    
    max-width: 500px;
    padding:  20px 30px;



}
.modal{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4);
    display: block;
    
        
    }
    .modalContent h1{
        color: #000;
font-size: 22px;
font-family: Poppins;
font-weight: 500;
margin: 30px 0 35px 0;
    }
    .hideModal{
        display: none;
    }
    .buttons{
        display: flex;
        justify-content: flex-end;
        grid-column-gap: 30px;
        margin-top: 20px;
    }
    .clear{
        border-radius: 45px;
        border: 1px solid var(--primary-color, #85CE54);
        background: var(--white, #FFF);
    border-radius: 100px;
    padding: 8px 20px;
    height: 38px;
padding: 12px 24px;
font-size: 12px;
color: #85CE54;

font-weight: 600;
    }
    .solid{
 border: none;
        
    border-radius: 100px;
    padding: 8px 20px;
    height: 38px;
padding: 12px 24px;
background: #FFB020;
color: var(--color-white-100, #FFF);
text-align: center;
font-size: 12px;

font-weight: 600;
    }
.download{
    border-radius: 45px;
background: var(--secondary-color, #AEA4BF);
padding: 0 25px;
height: 50px;
padding: 12px 24px;
border: none;
color: var(--color-white-100, #FFF);
text-align: center;
font-size: 18px;
display: flex;
align-items: center;
font-weight: 600;
margin-left: 20%;
}

.download img, .upload img{
    margin-left: 5px;
}
.upload{
    border-radius: 45px;
    background: var(--primary-color, #85CE54);
padding: 0 25px;
height: 50px;
padding: 12px 24px;
border: none;
color: var(--color-white-100, #FFF);
text-align: center;
font-size: 18px;
display: flex;
align-items: center;
font-weight: 600;
font-weight: 600;
margin-left: 23%;
}