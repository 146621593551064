
a{
  color: inherit;
  text-decoration: none;
}
body, html{
  width: 100%;
 
  height: 100vh;

}
input{
  outline: none;
}
.error_message{
  color: var(--error, #DB504A);
  font-size: 13px;
font-family: Cairo;
font-style: normal;
font-weight: 600;
margin: 0 20px 0 0;

}
.error_message_select{
  color: var(--error, #DB504A);
  font-size: 13px;
font-family: Cairo;
font-style: normal;
font-weight: 600;
margin: 0 20px 0 0;
}
.border_error{
  border: 1px solid #DB504A;
}
.plain-line .line-content {
  background: none;
  border: none;
}
.main_container{
  display: flex;

  background-color: #F7F8FA;

}

button{
  cursor: pointer;
  height: 35px;
  font-weight: 700;

padding: 5px 10px;
}

.subContainer{
  width: 100%;


  padding: 20px 30px;


}
.full_container{
  width: 100%;
  background-color: #F7F8FA;

  padding: 20px 30px;
}
.main_content{
  padding: 20px 50px;

}
.no_content{
  padding: 20px 50px;

}
.hide{
  display: none;
}

@media screen and (max-width: 890px) {
  .subContainer{
      max-width: fit-content;
      margin-left: 0;
      min-width: 95%;
      padding: 20px ;
      
  }
  .main_container{
    display: block;
  
    background-color: #F7F8FA;
    overflow-y: hidden;
  
    
    
  
  
  }

 
}

@media screen and (max-width:1400px){
  .main_content{
      padding: 25px;
  }

}

@media screen and (max-width: 450px) {
  .main_content{
      padding: 20px ;
  
  }
  .subContainer{
    width: 100%;
padding: 8px;
    
}

}
@media screen and (max-width:1300px){
  .main_content{
      padding: 50px;
  }
}



@media screen and (min-width: 1800px) {
  .subContainer{
    padding: 50px;
  }
  
}



/* Width and height of the scrollbar */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #85CE54;
  border-radius: 5px;
}

/* On hover, the handle */
::-webkit-scrollbar-thumb:hover {
  background: #0C462D;;
}

.loader_container .loader {
  border: 10px dotted  #c5f1a7;
  border-radius: 50%;
  border-top: 10px dotted #85CE54;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

}
.small_loader_container .loader {
  border: 5px dotted  #c5f1a7;
  border-radius: 50%;
  border-top: 5px dotted #85CE54;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

}
.button_loader{
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_button{
  background: #85CE54;
  /* Primary Color */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #85CE54;
  border-radius: 100px;
  color: #FFFFFF;
  border-radius: 150px;
  padding: 18px 20px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  width: 90px;
}
.button_loader div, .loading_button div{
  border: 5px dotted  #c5f1a7;
  border-radius: 50%;
  border-top: 5px dotted #ffffff;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader_container{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffff;
  border-radius: 14px;
  height: 75vh;
}

.place::placeholder{
  color: black !important;
}


/* Hide the default dropdown arrow */
#myDateInput::-webkit-calendar-picker-indicator {
  display: none;
}

/* Create a custom dropdown arrow */
#myDateInput:after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Style the dropdown container */
.custom-dropdown {
  position: relative;
}

/* Style the dropdown menu */
.custom-dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Style the dropdown menu items */
.custom-dropdown-menu li {
  list-style-type: none;
  padding: 5px 10px;
  cursor: pointer;
}

/* Highlight the selected item */
.custom-dropdown-menu li.selected {
  background-color: #f2f2f2;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.progress-bar {
  width: 100%;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin: 30px 0;
}

.progress_track {
  height: 100%;
  background-color: #85CE54 !important;
  transition: width 0.3s ease-in-out;
}

/* loader css */
.view_loader_container{
  height: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
}

.bars {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}

.bars div {
  position: absolute;
  left: 6px;
  width: 10px;
  background: #85CE54;
  animation: bars 1.5s cubic-bezier(0, 0.5, 0.5, 0.8) infinite;
}

.bars div:nth-child(1) {
  left: 6px;
  animation-delay: 0.3s;
}

.bars div:nth-child(2) {
  left: 22px;
  animation-delay: 0.6s;
}

.bars div:nth-child(3) {
  left: 38px;
  animation-delay: 0.8s;
}

@keyframes bars {
  0% {
    top: 8px;
    height: 84px;
  }
  100% {
    top: 48px;
    height: 12px;
  }
}