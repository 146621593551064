

.contentHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0D0F11;
    flex-wrap: wrap;
    font-family: 'Poppins', sans-serif;
}
.contentHeader h3{
    text-align: right;
    color: #0D0F11;
    font-size: 18px;
    font-weight: 600;
  
}
.contentHeader h1{
    font-weight: 700;
    font-size: 24px;
    margin: 0;
   
}
.contentHeader p{
    margin-top: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #9D9D9D;

}
.cardContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    font-family: 'Poppins', sans-serif;
}
.activity_card h4 {
    margin: 0;
    font-weight: 600;
font-size: 18px;



color: #333333;
    
}
.activity_card {
    height: 480px;
    background: #FFFFFF;
border-radius: 14px;
padding: 20px;
width: 250px;
/* overflow-y: scroll; */
font-family: 'Poppins', sans-serif;
border: none;
}
.list_of_years{
   position: absolute;
   padding: 0;
   height: 70px;
   overflow-y: scroll;
   background: #FFFFFF;
border-radius: 8px;
list-style: none;

width: 70px;
box-shadow: 1px 2px 5px 2px rgba(173,159,159,0.66);
-webkit-box-shadow: 1px 2px 5px 2px rgba(173,159,159,0.66);
-moz-box-shadow: 1px 2px 5px 2px rgba(173,159,159,0.66);
overflow-y: scroll;
font-family: 'Poppins', sans-serif;
border: none;
}
.list_of_years li:hover{
 background: #85CE54;
 color: white;
}
.list_of_years li{
   padding: 5px 10px;
   cursor: pointer;
   font-weight: 500;
   font-size: 15px;
   }
.filter{
    position: relative;
}
.activity_container, .report_container{
   height: 95%;
   overflow-y: auto;
}
.activity_card a {
    color: #85CE54;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column-gap: 5px;
    font-size: 15px;
}
.reports a {
    color:#DB504A;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column-gap: 5px;
    font-size: 15px;
}
.graph{
    background: #FFFFFF;
    border-radius: 14px;
    padding: 15px 50px;

    margin-top: 50px;
    height: 600px;
    font-family: 'Poppins', sans-serif;
}
.line{
  width: 1000px ;
  height: 500px ;

}
@media screen and (max-width:1800px) {

    .line{
        width: 1000px !important;
        height: 500px ;
}
}
.graph_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.graph_header button{
    background: #FFFFFF;
/* Primary Color */

border: 1px solid #85CE54;
border-radius: 10px;
padding: 5px 10px;
height: 35px;
display: flex;
align-items: center;
justify-content: center;
grid-column-gap: 5px;
}
.graph_header h1{
    margin: 0;
    font-weight: 500;
font-size: 24px;
line-height: 36px;

}
.graph_header p {
    font-weight: 400;
font-size: 14px;
margin: 0;


/* Text light */

color: #9D9D9D;
}
.activity_card h1{
    font-weight: 500;
font-size: 24px;
margin: 0;
font-family: 'Poppins', sans-serif;



color: #333333;
}

.activity_card p{
    margin: 0;
    font-weight: 400;
font-size: 13px;
line-height: 24px;
color: #9D9D9D;
}
.bottom_content{
    display: flex;
    margin-top: 50px;
    grid-column-gap: 30px;
    align-items: flex-start;
    width: 100%;

}

.chart{
    width: 250px;
}
.reports h1{
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 40px 0;
    font-family: 'Poppins', sans-serif;
    
    
    
    color: #333333;
}
.reports{
    display: flex;
    align-items: flex-start;
    width: 70%;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 14px;
    font-family: 'Poppins', sans-serif;
    height: 480px;
}
.report_list{
    
    padding-right: 40px ;
    height: 100%;

}
.donut{
    padding-left: 40px ;
    border-left: 1px solid #F0F0F0;
}
.item{
    display: flex;
    align-items: center;
    grid-column-gap: 15px;
    margin: 20px 0;
}
.no_content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
   height: 70%;
   background: #ffffff;
   margin-top: 20px;
   border-radius: 14px;

}
.no_content h6{
    font-size: 18px;
    font-weight: 400;
}
.no_content img{
    width: 140px;
}
.item h4{
    font-family: 'Cairo', sans-serif;
    font-weight: 600;
font-size: 18px;
margin: 0;
}
.item p{
    font-family: 'Cairo', sans-serif;
font-weight: 400;
font-size: 13px;
margin: 0;
line-height: 15px;
color: #9D9D9D;
}
.donut{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.donut h1{
    margin: 0;
}
.donut h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
font-size: 20px;
margin: 0;

color: #A5A5A5;


}
.donut span{
    display: flex;
    align-items: center;
    grid-column-gap: 15px;
    margin: 10px 0;
}
.donut span p{
    font-weight: 400;
font-size: 15px;
line-height: 22px;
margin: 0;

color: #0D0F11;
}
.donut span h4{
    font-weight: 700;
font-size: 22px;

margin: 0;
font-family: 'Cairo', sans-serif;
text-align: right;



color: #0D0F11;

}
@media screen and (max-width: 811px) {
    .cardContainer{

        justify-content: center;
    }
    .bottom_content{
        display: grid;
       row-gap: 20px;
    }
    .activity_card {
     
        width: 100%;
       
        }
        .reports{
            width: 100%;
        }

}

@media screen and (max-width: 580px) {
    .contentHeader{
        justify-content: center;
    }
    .contentHeader h1,.contentHeader p,.contentHeader h3{
        text-align: center;
    }
  
}


@media screen and (max-width:1300px){

    .cardContainer{

       
        grid-column-gap: 40px;
        grid-row-gap: 40px;
      
    }
}
@media screen and (min-width:1800px) {

    .line{
  width: 2015px !important;
    height: 700px ;
}
.graph{
    height: 800px;
}
.activity_card {
 
width: 350px;

}
.report_list{
    
    width: 350px;

}
}

@media screen and (min-width: 2000px) {

    .activity_card {
        height: 480px;
        background: #FFFFFF;
    border-radius: 14px;
    padding: 30px;
    width: 400px;
    /* overflow-y: scroll; */
    font-family: 'Poppins', sans-serif;
    border: none;
    }
    .report_list{
       width: 60%;
    }
}
@media screen and (min-width: 2300px) {
    .reports{
        display: flex;
        align-items: flex-start;
        width: 80%;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 14px;
        font-family: 'Poppins', sans-serif;
        height: 480px;
    }
    .activity_card {
     
    width: 800px;
   
    }
    .report_list{
        width: 100%;
     }
}