.navbar{
    display: flex;
   
    align-items: center;
    padding: 10px 0px;
 
 

}
.sidebar{display: none;}
.navbar_full .logo{
  display: none;

}
.placeholder{
    background: #85CE54;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 5px;
}
.placeholder h3{
    color: #FFFFFF;
    font-size: 13px;
    margin: 0;
}
.navbar_full{
    padding: 10px 20px;
  
}
.navbar_full .navbar_content{
    width: 100%;
    padding-left: 0;
}
.logo{
    display: flex;
    justify-content: center;
    width: 20%;
    max-width: 350px;
 
}
.logo img{
    width: 150px;
    
}
.navbar_content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #ffffff;
    padding: 0px 30px;
  
}
.toggleMenu{
    width: 28px;
}
.toggleMenuTwo{
    display: none;
}
.dropdown{
    width: 10px;
}
.profile{
    display: flex;
    align-items: center;
    grid-column-gap: 15px;
    background: #F7F8FA;
    border-radius: 40px;
    padding: 5px;
    padding-right: 30px;
    position: relative;
    cursor: pointer;
}
.profile h1{
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    color: #333333;

}
.profile p{
    margin: 0;
    font-size: 10px;
    color: #A5A5A5;
}
.profileImage{
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.logout{
    position: absolute;
    right: 0;
    top: 50px;
    width: 250px;

    height: 90px;
}
@media  (min-width: 450px) and (max-width: 890px){
    .sidebar{
     
        background: #ffffff;
        position: absolute;
    
        top: 0;
        left: 70%;
        width: 30%;
        height: max-content;
        display: block;
        z-index: 2;
        font-family: 'SF Pro Display', sans-serif;
    
        }
}
@media screen and (max-width: 890px) {
    .navbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .close{
        margin: 10px;
    }
    .logout_div{
        margin-right: 45px;
    }
   
    .sidebar .profile{
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        background: #F7F8FA;
        border-radius: 40px;
        padding: 10px;
 
        position: relative;
        cursor: pointer;
        margin: 20px 15px;
    }
    .sidebar .profile h1{
        margin: 0;
        font-size: 15px;
        font-weight: 700;
        color: #333333;
    
    }
    .sidebar .profile p{
        margin: 0;
        font-size: 13px;
        color: #A5A5A5;
    }
    .sidebar .profileImage{
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
   .sidebar .placeholder{
        background: #85CE54;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1px;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        padding: 5px;
    }
    .placeholder h3{
        color: #FFFFFF;
        font-size: 15px;
        margin: 0;
    }

    .toggleMenuTwo{
        display: block;
    }
    .toggleMenuTwo img{
        width: 20px;
    }
    .toggleMenu{
        display: none;
    }
    .profile {
        display: none;
    }
    .navbar_content{
      width: 40%;
      padding: 0 15px;
      
    }
  
    .half_sidebar{
        background: #ffffff;
        left: 0;
    height: 100vh;
        position: relative;
    }
    .sidebar ul{
        list-style: none;
        padding-left: 0;
        margin-top: 30px;
    }
    .sidelist , .sidebar .hoverlist{
        list-style: none;
        padding-left: 0;
        margin-top: 0 !important;
        
    }
    
    .half_sidebar .hoverlist{
        position: absolute;
        background: #FFFFFF;
     box-shadow: 0px 4px 3px -1px rgba(133, 206, 84, 0.06);
     width: max-content;
     margin-left: 80px;
     padding: 5px 10px;
     list-style: none;
    }
    .half_sidebar .sidelist{
        display: none;
    }
    .half_sidebar .sidelist li {
        margin-left: 0px;
    }
    .sidebar ul li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column-gap: 15px;
        margin-top: 5px;
        padding: 15px 20px;
        width: 80%;
       
     
    }
    .sidelist li, .sidebar .hoverlist li{
        margin-left: 23%;
        margin-top: 10px !important;
        color: #0D0F11;
        padding:2px 5px !important;
        width: 100% !important;
        font-size: 13px;
        font-weight: 400;
    
    }
    .half_sidebar .hoverlist li{
        color: #0D0F11;
        padding:2px 10px !important;
        width: 100% !important;
        font-size: 13px;
        font-weight: 400;
        height: 30px;
    }
    
    .sidebar ul li p{
        color: #0D0F11;
        font-weight: 600;
        text-align: left;
        margin: 0;
        width: 100px;
    }
    .icon{
        width: 25px;
        margin-left: 20px;
    }
    .iconButton{
        background: none;
       border: none;
        margin-left: 18px;
        font-size: 23px;
    }
    
    .backIcon{
        width: 20px;
    }
    .backIcon_white{
        width: 15px;
    }
    .active li{
        background: linear-gradient(90deg, #01553C 0%, #85CE54 100%);
    border-radius: 30px 0px 0px 30px;
    color: #ffffff;
    width: 80%;
    
    }
    .active li p{
        color: #ffffff !important;
    }
    .side_active li, .half_sidebar .hoverlist .side_active li , .sidebar .hoverlist .side_active li{
        color: #85CE54;
        font-weight: 600;
    }
    
    .half_sidebar .backIcon, .half_sidebar .backIcon_white, .half_sidebar p{ 
       display: none;  
    } 
    .half_sidebar .icon{
        padding: 10px 20px ;
        margin-left: 0px;
    }
    .half_sidebar ul {
        padding-left: 0;
    
    }
    .half_sidebar ul li{
        width: 90%;
        padding: 5px;
    }
    
    .logout{
        display: none;
    }
 }
 @media screen and (min-width: 2000px){
    .toggleMenu{
        width: 50px;
    }
    .dropdown{
        width: 20px;
    }
    .logo img{
        width: 200px;
        margin-top: 20px;
    }
    .placeholder{
        background: #85CE54;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        padding: 5px;
    }
    .placeholder h3{
        color: #FFFFFF;
        font-size: 20px;
        margin: 0;
    }
    .profile{
        display: flex;
        align-items: center;
        grid-column-gap: 15px;
        background: #F7F8FA;
        border-radius: 40px;
        padding: 20px;
        padding-right: 20px;
    }
    .profile h1{
        margin: 0;
        font-size: 25px;
        font-weight: 700;
        color: #333333;
    
    }
    .profile p{
        margin: 0;
        font-size: 18px;
        color: #A5A5A5;
    }
    .profileImage{
        width: 60px;
        height: 60px;
    }
 }
@media (min-width: 1800px) and (max-width:2000px){
    .toggleMenu{
        width: 40px;
    }
    .dropdown{
        width: 15px;
    }
    .placeholder{
        background: #85CE54;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1px;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        padding: 5px;
    }
    .placeholder h3{
        color: #FFFFFF;
        font-size: 20px;
        margin: 0;
    }
    .logo img{
        width: 250px;
        margin-top: 20px;
    }
    .profile{
        display: flex;
        align-items: center;
        grid-column-gap: 15px;
        background: #F7F8FA;
        border-radius: 40px;
        padding: 10px;
        padding-right: 20px;
    }
    .profile h1{
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        color: #333333;
    
    }
    .profile p{
        margin: 0;
        font-size: 15px;
        color: #A5A5A5;
    }
    .profileImage{
        width: 55px;
        height: 55px;
    }
}
@media screen and (max-width: 1080px) {
    .logo img{
        width: 130px;
    }
}
@media screen and (max-width: 450px){
    .navbar{
        width: 100%;
    }
    .close{
        margin: 10px;
    }
    .logout_div{
        margin-right: 45px;
    }
   
    .sidebar .profile{
        display: flex;
        align-items: center;
        grid-column-gap: 30px;
        background: #F7F8FA;
        border-radius: 40px;
        padding: 10px;
 
        position: relative;
        cursor: pointer;
        margin: 20px 15px;
    }
    .sidebar .profile h1{
        margin: 0;
        font-size: 15px;
        font-weight: 700;
        color: #333333;
    
    }
    .sidebar .profile p{
        margin: 0;
        font-size: 13px;
        color: #A5A5A5;
    }
    .sidebar .profileImage{
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    .sidebar{
        width: 20%;
    background: #ffffff;
    position: absolute;
    right: 0 !important;
    z-index: 2;
    top: 0;
    width: 70%;
  height: max-content;
    display: block;
    font-family: 'SF Pro Display', sans-serif;

    }
    .logo{
        display: block;
        padding-left: 10px;
        width: 20%;
        height: 30px;
     
    }
    .logo img{
        width: 100px;
    }

 
}