.content_header h1 {
  font-weight: 600;
  font-size: 24px;
  color: #313131;
  margin: 0;
  margin-bottom: 5px;
}

.content_header p {
  font-weight: 600;
  font-size: 12px;
  color: #9D9D9D;
  margin: 0;
}

.content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  flex-wrap: wrap;
  grid-row-gap: 20px;
}

.form_container {
  background: #FFFFFF;

  border-radius: 10px;

  margin-top: 20px;
  padding: 15px 20px;


}

.buttons {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 30px;
}

.clear {
  background: #FFFFFF;

  border: 1px solid #85CE54;
  border-radius: 100px;
  color: #85CE54;
  border-radius: 100px;
  padding: 8px 20px;
}

.solid,
.solid_add {
  background: #85CE54;
  /* Primary Color */

  border: 1px solid #85CE54;
  border-radius: 100px;
  color: #FFFFFF;
  border-radius: 150px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
}

.next_icon {
  background: url("../../../Assets/left.svg");

  height: 60%;
  background-size: contain;
  width: 8px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

.solid:hover>.next_icon {
  background: url("../../../Assets/bulk_green.svg");

  height: 10px;
  background-size: contain;
  width: 10px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

.solid:hover,
.solid_add:hover {
  background: #FFFFFF;
  color: #85CE54;
  border: 1px solid #85CE54;
}

.add_image {
  background: url("../../../Assets/add-icon.png");
  background: contain;
  height: 100%;
  background-size: contain;
  width: 20px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
}

.solid_add:hover>.add_image {
  background: url("../../../Assets/green-square.svg");
  background: contain;
  height: 100%;
  background-size: contain;
  width: 20px;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;

}

.solid:active {
  color: #FFFFFF;
  background: #0C462D;
  border: none;

}


.next_container h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.2px;

  color: #DB504A;
}

.next_container .header {
  margin-top: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom_switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_switch:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.custom_switch {
  background-color: #85ce54;
}

input:focus+.custom_switch {
  box-shadow: 0 0 1px #85ce54;
}

input:checked+.custom_switch:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded custom_switch s */
.custom_switch {
  border-radius: 34px;
}

.custom_switch:before {
  border-radius: 50%;
}

.check_input {
  display: flex;
  align-items: center;

  grid-column-gap: 10px;
}

.next_container .next_form_container {
  display: flex;
  flex-wrap: wrap;

  overflow: hidden;
  grid-column-gap: 30px;



}

.next_container .form_input {
  display: grid;
  margin: 10px 0;


}

.modalContent .form_input,.modalContent .select_input {
  display: grid;
  margin: 20px 0;
}

.next_container .buttons {
  margin-top: 20px !important;
}

.next_container .form_input label {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;

  letter-spacing: 0.4px;

  color: #0d0f11;
}
input[type=file]{
  display: none;
}

.modalContent .form_input label, .modalContent .select_input label {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;

  letter-spacing: 0.4px;

  color: #0d0f11;
}

.next_container .form_input input {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 300px;
  height: 30px;
  outline: none;
}

.modalContent .form_input input {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 300px;
  height: 30px;
  outline: none;
}

.timesheet_container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
  grid-row-gap: 10px;
}

.timesheet_container .item {
  display: flex;
  grid-column-gap: 10px;
  align-items: center;

}

.timesheet_container .item h1 {
  margin: 0;
  font-size: 23px;
}

.rate {
  margin-left: 30px;
  display: flex;
  grid-column-gap: 20px;
  flex-wrap: wrap
}

.add_rate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.modalContent {

  border-radius: 14px;
  background: var(--color-white, #FFF);
  margin: 10% auto;


  max-width: 320px;
  padding: 20px 30px;



}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  display: block;


}

.modalContent h1 {
  color: #000;
  font-size: 22px;
  font-family: Poppins;
  font-weight: 500;
  margin: 30px 0 35px 0;
}

.hideModal {
  display: none;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 30px;
  margin-top: 20px;
}

.clear {
  border-radius: 45px;
  border: 1px solid var(--primary-color, #85CE54);
  background: var(--white, #FFF);
  border-radius: 100px;
  padding: 8px 20px;
  height: 38px;
  padding: 12px 24px;
  font-size: 12px;
  color: #85CE54;

  font-weight: 600;
}

.download {
  border-radius: 45px;
  background: var(--secondary-color, #AEA4BF);
  padding: 0 25px;
  height: 50px;
  padding: 12px 24px;
  border: none;
  color: var(--color-white-100, #FFF);
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-left: 20%;
}
.colorPicker{
  width: 30%;
}

.upload {
  border-radius: 45px;
  background: var(--primary-color, #85CE54);
  padding: 0 25px;
  height: 40px;
  padding: 10px 20px;
  width: 100%;
  border: none;
  color: var(--color-white-100, #FFF);
  text-align: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-weight: 600;
 
}
.addFile{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

@media screen and (max-width: 450px) {
  .timesheet_container {
    margin-top: 20px;
  }

  .rate {
    margin-left: 0;
    margin-top: 8px;
  }

  .add_rate {
    display: grid;

  }

  .add_rate button {
    width: 30%;
    margin-top: 8px;
  }
}

@media screen and (min-width: 1800px) {

  .next_container .form_input input,
  .modalContent .form_input input {
    width: 490px;
    height: 40px;

  }
  .modalContent {

    border-radius: 14px;
    background: var(--color-white, #FFF);
    margin: 10% auto;
  
    max-width: max-content;
    padding: 20px 40px;
  
  
  
  }

}
.label{
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0 5px 0;

  letter-spacing: 0.4px;

  color: #0d0f11;
}
.color_pick{
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px ;
  opacity: 0.8;
  width: 300px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
 
}
.color_pick section{
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;

  width: 290px;
  height: 20px;
}