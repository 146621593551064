.content_header h1{
    font-weight: 600;
font-size: 24px;
color: #313131;
margin: 0;
margin-bottom: 5px;
}
.content_header p{
    font-weight: 600;
font-size: 12px;
color: #9D9D9D;
margin: 0;
}
.content_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.form_container{
    background: #FFFFFF;

border: 1px solid rgba(4, 4, 21, 0.08);
border-radius: 10px;

margin-top: 20px;
padding: 15px 20px;
}

.buttons{
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 30px;
}
.clear{
    background: #FFFFFF;

border: 1px solid #85CE54;
border-radius: 100px;
color: #85CE54;
border-radius: 100px;
padding: 8px 20px;
}
.solid{
    background: #85CE54;
/* Primary Color */

border: 1px solid #85CE54;
border-radius: 100px;
color:  #FFFFFF;
border-radius: 150px;
padding: 8px 20px;
}