.live button{
    background: #B5FFE9;
}


.buttons{
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 30px;
}
.clear{
    background: #FFFFFF;

border: 1px solid #85CE54;
border-radius: 100px;
color: #85CE54;
border-radius: 100px;
padding: 8px 20px;
}
.solid{
    background: #85CE54;
/* Primary Color */

border: 1px solid #85CE54;
border-radius: 100px;
color:  #FFFFFF;
border-radius: 150px;
padding: 8px 20px;
display: flex;
align-items: center;
grid-column-gap: 10px;
}
.solid:hover{
    background: #FFFFFF;
    color: #85CE54;
    border: 1px solid #85CE54;
}

.solid:active{
    color: #FFFFFF;
    background: #0C462D;
    border: none;
    
}

.bulk {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column-gap: 5px;
    height: 45px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  
    color: #ffffff;
    border: none;
    background: #85ce54;
    border-radius: 100px;
    padding: 5px 20px;
    margin-top: 20px;
  }
  
  .bulk img {
    width: 35px;
  }
  .filter{
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap:10px;
    margin-top: 10px;
}
.filter{
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap:10px;
}

.filter .select_input {
    width: 320px;
    height: 60px;
    margin: 8px 0;
  }
  .filter  .select_input label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
  
    letter-spacing: 0.4px;
  
    color: #0d0f11;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .custom_switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .custom_switch:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .custom_switch {
    background-color: #85ce54;
  }
  
  input:focus + .custom_switch {
    box-shadow: 0 0 1px #85ce54;
  }
  
  input:checked + .custom_switch:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
  
  /* Rounded custom_switch s */
  .custom_switch {
    border-radius: 34px;
  }
  
  .custom_switch:before {
    border-radius: 50%;
  }
  .check_input{
    display: flex;
    align-items: center;

    grid-column-gap:10px;
  }
  .next_container .next_form_container{
    display: flex;
    flex-wrap: wrap;
   
   overflow: hidden;
   grid-column-gap:100px;
   grid-row-gap:20px;

 

  }
   .next_container .form_input {
     display: grid;
  margin: 10px 0;
 

  }
  .next_container .buttons{
    margin-top: 20px !important;
  }
  .next_container .form_input label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
  
    letter-spacing: 0.4px;
  
    color: #0d0f11;
  }
  .next_container .form_input input {
    mix-blend-mode: normal;
    border: 1px solid rgba(4, 4, 21, 0.08);
    border-radius: 100px;
    color: #0d0f11;
    padding: 5px 8px;
    opacity: 0.8;
    width: 200px;
    height: 30px;
    outline: none;
  }
  .action_icons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.action_icons div{
    display: flex;
    grid-column-gap: 10px;
    align-items: center;
    grid-row-gap: 20px;
    flex-wrap: wrap
}
.action_icons img{
    width: 20px;
}
.modalContent {

  border-radius: 14px;
  background: var(--color-white, #FFF);
  margin: 10% auto;


  max-width: 320px;
  padding: 20px 30px;



}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  display: block;


}

.modalContent h1 {
  color: #000;
  font-size: 22px;
  font-family: Poppins;
  font-weight: 500;
  margin: 30px 0 35px 0;
}

.hideModal {
  display: none;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 30px;
  margin-top: 20px;
}

.clear {
  border-radius: 45px;
  border: 1px solid var(--primary-color, #85CE54);
  background: var(--white, #FFF);
  border-radius: 100px;
  padding: 8px 20px;
  height: 38px;
  padding: 12px 24px;
  font-size: 12px;
  color: #85CE54;

  font-weight: 600;
}

.download {
  border-radius: 45px;
  background: var(--secondary-color, #AEA4BF);
  padding: 0 25px;
  height: 50px;
  padding: 12px 24px;
  border: none;
  color: var(--color-white-100, #FFF);
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-left: 20%;
}

.upload {
  border-radius: 45px;
  background: var(--primary-color, #85CE54);
  padding: 0 25px;
  height: 40px;
  padding: 10px 20px;
  width: 100%;
  border: none;
  color: var(--color-white-100, #FFF);
  text-align: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-weight: 600;
 
}
.addFile{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}
input[type=file]{
  display: none;
}
.modalContent .form_input,.modalContent .select_input {
  display: grid;
  margin: 20px 0;
}

.modalContent .form_input label, .modalContent .select_input label {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;

  letter-spacing: 0.4px;

  color: #0d0f11;
}

.next_container .form_input input {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 300px;
  height: 30px;
  outline: none;
}

.modalContent .form_input input {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 300px;
  height: 30px;
  outline: none;
}




.filter{
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 10px;
  grid-row-gap:10px;
}
.filter .form_input {
  display: grid;
  margin: 8px 0;
}
.filter .form_input label{
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;

  letter-spacing: 0.4px;

  color: #0d0f11;
}
.filter .input_container{
  margin-top: 10px;
}

.filter .form_input input {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 300px;
  height: 30px;
  outline: none;
}
.filter .select_input {
  width: 320px;
  height: 60px;
  margin: 8px 0;
}
.filter .form_input label {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;

  letter-spacing: 0.4px;

  color: #0d0f11;
}
.filter .form_input input {
  mix-blend-mode: normal;
  border: 1px solid rgba(4, 4, 21, 0.08);
  border-radius: 100px;
  color: #0d0f11;
  padding: 5px 8px;
  opacity: 0.8;
  width: 300px;
  height: 30px;
  outline: none;
}
@media screen and (min-width:1800px) {
  .filter{
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: 30px;
      grid-row-gap:30px;
  }
  .filter .form_input input {

    height: 40px;

  }
  
}
.filter  .select_input label {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;

  letter-spacing: 0.4px;

  color: #0d0f11;
}
