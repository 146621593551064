.content_header h1 {
    font-weight: 600;
    font-size: 24px;
    color: #313131;
    margin: 0;
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;
  }
  
  .content_header p {
    font-weight: 600;
    font-size: 12px;
    color: #9D9D9D;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }
.content_header span{
    color: #DB504A;
}  
