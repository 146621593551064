.sidebar{
    width: 20%;
    background: #ffffff;
    left: 0;
height: 100vh;
    display: block;
    font-family: 'SF Pro Display', sans-serif;
    max-width: 320px;
}
.half_sidebar{
    background: #ffffff;
    left: 0;
height: 100vh;
    position: relative;
}
.sidebar ul{
    list-style: none;
    padding-left: 0;
    margin-top: 30px;
}
.sidelist , .sidebar .hoverlist{
    list-style: none;
    padding-left: 0;
    margin-top: 0 !important;
    
}
 
.half_sidebar .hoverlist{
    position: absolute;
    background: #FFFFFF;
 box-shadow: 0px 4px 3px -1px rgba(133, 206, 84, 0.06);
 width: max-content;
 margin-left: 80px;
 padding: 5px 10px;
 list-style: none;
}
.half_sidebar .sidelist{
    display: none;
} 
.half_ {
    margin-left: 0px;
}
.sidebar ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 15px;
    margin-top: 5px;
    padding: 15px 20px;
    width: 80%;
   
 
}
.sidelist li, .sidebar .hoverlist li{
    margin-left: 33%;
    margin-top: 10px !important;
    color: #0D0F11;
    padding:2px 5px !important;
    width: 100% !important;
    font-size: 15px;
    font-weight: 400;

}
.half_sidebar .hoverlist li{
    color: #0D0F11;
    padding:2px 10px !important;
    width: 100% !important;
    font-size: 13px;
    font-weight: 400;
    height: 30px;
}

.sidebar ul li p{
    color: #0D0F11;
    font-weight: 600;
    text-align: left;
    margin: 0;
    width: 100px;
}
.icon_image{
    background: url("../../../Assets/add-icon.png");
    background: contain;
    height: 100%;
    background-size: contain;
    width: 20px;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
  }
.icon{
    width: 25px;
    margin-left: 20px;
}

.backIcon{
    background: url("../../../Assets/side.svg");
    background: contain;
    height: 100%;
    background-size: contain;
    width: 8px;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
}
.backIcon_white{
    background: url("../../../Assets/down-white.svg");
    background: contain;
    height: 100%;
    background-size: contain;
    width: 15px;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
}
.active li{
    background: linear-gradient(90deg, #01553C 0%, #85CE54 100%);
border-radius: 0px 30px 30px 0px;
color: #ffffff;
width: 80%;

}
.active li p{
    color: #ffffff !important;
}
.side_active li, .half_sidebar .hoverlist .side_active li , .sidebar .hoverlist .side_active li{
    color: #85CE54;
    font-weight: 600;
}

.half_sidebar .backIcon, .half_sidebar .backIcon_white, .half_sidebar p{ 
   display: none;  
} 
.half_sidebar .icon{
    padding: 10px 20px ;
    margin-left: 0px;
}
.half_sidebar ul {
    padding-left: 0;

}
.half_sidebar ul li{
    width: 90%;
    padding: 5px;
}
@media screen and (max-width: 1080px) {

    .sidebar{
        padding: 20px 0px;
     
    }
    .sidebar ul{
      margin-top: 20px;
      padding-right:20px;
    }
    .backIcon{
        width: 7px;
    }
    .sidebar ul li p{
        width: 100px;
        font-size: 15px;
    }
    .sidebar ul li{   
        margin-top: 5px;
        margin-bottom: 5px;
        grid-column-gap: 5px;
        width: 100%;
        padding: 10px;

    }
    .icon{

        margin-left: 10px;
    }
    
}
@media (max-width: 933px) and (min-width: 892px){
    .icon{

        margin-left: 5px;
    }
    .sidebar ul li{
   
        padding: 15px;

     
    }
}
@media screen and (min-width: 1800px){
    .sidebar{
        padding: 0px 0px;
    }
    .backIcon{
        width: 10px;
    }
 
    .icon{
        width: 35px;
    }
    .sidebar ul{
        margin-top: 20px;
    }
    .sidebar ul li{
        padding: 20px;
    }
 
    .sidebar ul li p{
        width: 150px;
        font-size: 20px;
    }
    .sidelist li, .sidebar .hoverlist li{
        margin-left: 30%;
    }

}


@media screen and (max-width: 890px) {
   .sidebar{
    display: none;
   }
}

/* @media  (min-width:2000px) and (max-width:2400px){
    .sidelist li, .sidebar .hoverlist li{
        font-size: 25px;
        margin-left: 30%;
   
       }
}
@media screen and (min-width:2400px){
    .sidelist li, .sidebar .hoverlist li{
        font-size: 25px;
        margin-left: 33%;
   
       }
}
@media screen and (min-width:2000px){
    .icon{
        width: 40px;
        margin-left: 20px;
    }
    
    
    .sidebar ul li p{
        width: 200px;
        font-size: 30px;
    }
    .backIcon{
        width: 13px;
    }
    .backIcon_white{
        width: 20px;
    }
  
    .sidebar ul li{
        padding: 30px 45px 30px 30px;
    }
    .active li{
        background: linear-gradient(90deg, #01553C 0%, #85CE54 100%);
    border-radius: 0px 45px 45px 0px;
    color: #ffffff;
    width: 80%;
    
    }

}
 */
